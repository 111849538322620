/* eslint-disable react/no-danger */
import Head from 'next/head';

const Header = () => {
  const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

  return (
    <Head>
      <meta charSet="UTF-8" />
      <title>Agenda Virtual Mancomunitat dels ports</title>
      <meta
        name="description"
        content="Agenda Virtual de la comarca de Els Ports, encuentra todos los eventos, actividades, conciertos conferencias y mucho más en un sólo lugar"
      />
      <script
        src={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`}
      />
      <script src="https://apis.google.com/js/api.js" type="text/javascript" />

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-212912556-1"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-212912556-1', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </Head>
  );
};

export default Header;
