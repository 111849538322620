import { actionTypes } from './actions';

const initialState = {};

export const reducer = (state = initialState, { type, payload }) => {
  const reducerTypes = {
    [actionTypes.EVENTS_PENDING]: {
      ...state,
      isLoadingEvents: true,
      errorEvents: false,
    },
    [actionTypes.EVENTS_SUCCESS]: {
      ...state,
      isLoadingEvents: false,
      errorEvents: false,
      ...payload,
    },

    [actionTypes.EVENTS_ERROR]: {
      ...state,
      isLoadingEvents: false,
      errorEvents: true,
    },
    [actionTypes.EVENT_PENDING]: {
      ...state,
      isLoadingEvents: true,
      errorEvents: false,
    },
    [actionTypes.EVENT_SUCCESS]: {
      ...state,
      isLoadingEvents: false,
      errorEvents: false,
      ...payload,
    },

    [actionTypes.EVENT_ERROR]: {
      ...state,
      isLoadingEvents: false,
      errorEvents: true,
    },

    [actionTypes.SET_FOCUSED_EVENT]: {
      ...state,
      ...payload,
    },
  };

  return reducerTypes[type] || state;
};

export default reducer;
