/* eslint-disable no-console */
import Router from 'next/router';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/nextjs';

export const errorHandler = async (error, message) => {
  console.error(error);
  Sentry.captureException(error?.response);

  if (error?.response?.status === 401) {
    console.log(error.response);

    await Cookies.remove('token');
    Router.push('/login');
    toast('Tu sesión ha caducado, por favor vuelve a hacer login');
  } else if (message) {
    toast(message);
  } else {
    toast(
      'Algo ha ido mal, por favor recarga la página y vuelve a intentarlo, si continua sin funcionar, puedes ponerte en contacto con el administrador'
    );
  }
};
