export const buildFilterSearch = ({
  dateRange,
  query,
  eventCategories,
  eventType,
  municipio,
}) => {
  const paramFilters = {
    ...(dateRange && { finishDate_gte: dateRange[0] }),
    ...(dateRange && { initDate_lte: dateRange[1] }),
    ...(query && { title_contains: query }),
    ...(eventType && { 'eventType.type_in': eventType.type }),
    ...(eventCategories && {
      'eventCategories.category_in': [
        ...eventCategories.map((cat) => cat.category),
      ],
    }),
    ...(municipio && {
      'municipio.label_in': municipio.label,
    }),
  };

  return paramFilters;
};
