import { createTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#C9C2B3',
    },
    secondary: {
      main: '#E60C43',
    },
  },
});

export default theme;
