import jsCookie from 'js-cookie';
import Router from 'next/router';
import { toast } from 'react-toastify';

import axios from 'axios';

import {
  registerUser,
  loginUser,
  getMeInfo,
  changePassword,
  sendRestorePassMail,
  deleteUser,
  updateUserInfo,
} from '../../services/userServices';
import { loginRedirectToConfirm } from '../../utils/redirects';

export const actionTypes = {
  USER_REGISTER_PENDING: 'USER_REGISTER_PENDING',
  USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  USER_REGISTER_ERROR: 'USER_REGISTER_ERROR',
  USER_LOGIN_PENDING: 'USER_LOGIN_PENDING',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_FORGOT_PASSWORD_PENDING: 'USER_FORGOT_PASSWORD_PENDING',
  USER_FORGOT_PASSWORD_SUCCESS: 'USER_FORGOT_PASSWORD_SUCCESS',
  USER_FORGOT_PASSWORD_ERROR: 'USER_FORGOT_PASSWORD_ERROR',
  USER_DELETE_ACCOUNT_PENDING: 'USER_DELETE_ACCOUNT_PENDING',
  USER_DELETE_ACCOUNT_SUCCESS: 'USER_DELETE_ACCOUNT_SUCCESS',
  USER_DELETE_ACCOUNT_ERROR: 'USER_DELETE_ACCOUNT_ERROR',
  INTRANET_PENDING: 'INTRANET_PENDING',
  USER_DATA: 'USER_DATA',
  INTRANET_ERROR: 'INTRANET_ERROR',
  USER_UPDATE_PENDING: 'USER_UPDATE_PENDING',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
};

export const register = (newUser) => async (dispatch) => {
  dispatch({
    type: actionTypes.USER_REGISTER_PENDING,
  });

  const resData = await registerUser(newUser);

  if (resData) {
    dispatch({
      type: actionTypes.USER_REGISTER_SUCCESS,
      payload: resData,
    });

    loginRedirectToConfirm(resData);
  } else {
    dispatch({
      type: actionTypes.USER_REGISTER_ERROR,
      payload: resData,
    });
  }
};

export const login = (credentials) => async (dispatch) => {
  dispatch({
    type: actionTypes.USER_LOGIN_PENDING,
  });

  const resData = await loginUser(credentials);

  if (resData) {
    const token = resData.jwt;
    console.log(token);
    axios.defaults.headers.authorization = `Bearer ${token}`;
    await jsCookie.set('token', token);
    dispatch({
      type: actionTypes.USER_LOGIN_SUCCESS,
      payload: resData.user,
    });
    loginRedirectToConfirm(resData);
  } else {
    dispatch({
      type: actionTypes.USER_LOGIN_ERROR,
      payload: resData,
    });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: actionTypes.USER_LOGOUT,
  });
};

export const getUserMe = () => async (dispatch) => {
  dispatch({
    type: actionTypes.INTRANET_PENDING,
  });

  const userInfo = await getMeInfo();

  if (userInfo) {
    dispatch({
      type: actionTypes.USER_DATA,
      payload: userInfo,
    });
  } else {
    dispatch({
      type: actionTypes.INTRANET_ERROR,
    });
  }
};

export const changeUserPassword = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.USER_LOGIN_PENDING,
  });

  const res = await changePassword(data);

  if (res?.jwt) {
    dispatch({
      type: actionTypes.USER_LOGIN_SUCCESS,
    });
    toast('La contraseña ha sido modificada correctamente');
    Router.push('/login');
  } else {
    toast(
      'Algo ha ido mal, asegúrate de que has introducido un correo electrónico válido, si el problema persiste, puedes ponerte en contacto con info@agendaelsports.es'
    );
    dispatch({
      type: actionTypes.USER_LOGIN_ERROR,
    });
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  dispatch({
    type: actionTypes.USER_LOGIN_PENDING,
  });

  const res = await sendRestorePassMail(email);

  if (res?.ok) {
    dispatch({
      type: actionTypes.USER_LOGIN_SUCCESS,
    });
    toast(
      'Pronto recibirás un mail con las intrucciones para recuperar tu contraseña. Revisa la bandeja de Spam o Promociones si no lo recibes en tu bandeja principal'
    );
    Router.replace('/');
  } else {
    toast(
      'Algo ha ido mal, asegúrate de que has introducido un correo electrónico válido, si el problema persiste, puedes ponerte en contacto con info@agendaelsports.es'
    );
    dispatch({
      type: actionTypes.USER_LOGIN_ERROR,
    });
  }
};

export const deleteUserAccount = (password) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.USER_LOGIN_PENDING,
  });

  const {
    user: { email, id },
  } = getState();

  const res = await deleteUser(email, password, id);

  if (res) {
    jsCookie.remove('token');
    axios.defaults.headers.authorization = '';
    dispatch({
      type: actionTypes.USER_LOGIN_SUCCESS,
    });
    await Router.replace('/');
    toast(
      'Tu usuario ha sido eliminado correctamente, si quieres volver a registrarte puedes hacerlo de nuevo en la página de Acceso'
    );
  } else {
    dispatch({
      type: actionTypes.INTRANET_ERROR,
    });
  }
};

export const updateUser = (userId, updatedUser) => async (dispatch) => {
  dispatch({
    type: actionTypes.USER_UPDATE_PENDING,
  });

  const res = await updateUserInfo(userId, updatedUser);

  if (res) {
    dispatch({
      type: actionTypes.USER_UPDATE_SUCCESS,
      payload: res,
    });
  } else {
    dispatch({
      type: actionTypes.INTRANET_ERROR,
    });
  }
};
