import { useMemo } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './combineReducers';

export const useStore = (initialState) => {
  function initializeStore() {
    const store = createStore(
      rootReducer,
      initialState,
      composeWithDevTools(applyMiddleware(thunkMiddleware))
    );

    return store;
  }

  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
};
