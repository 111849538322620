import axios from 'axios';

import { errorHandler } from '../utils/errorHandler';
import { buildFilterSearch } from '../utils/buildFilterSearch';

export const fetchUserEvents = async ({ userId, limit, page }) => {
  const start = () => limit * (page - 1);

  const params = {
    user: userId,
    _start: start(),
    _limit: limit,
    _sort: 'initDate:ASC',
  };

  try {
    const { data, status } = await axios.get('/events', {
      params,
    });

    const resTotal = await axios.get('/events/count', {
      params,
    });

    if (status === 200 && resTotal.status === 200) {
      return { events: [...data], total: resTotal.data };
    }
  } catch (error) {
    errorHandler(error);
  }
};

export const fetchFeedEvents = async ({ userId, limit, page, filters }) => {
  const start = () => limit * (page - 1);

  const params = {
    user: userId,
    _start: start(),
    _limit: limit,
    _sort: 'initDate:ASC',
    isValidated: true,
    ...buildFilterSearch(filters),
  };

  try {
    const { data, status } = await axios.get('/events', {
      params,
    });

    const resTotal = await axios.get('/events/count', {
      params,
    });

    if (status === 200 && resTotal.status === 200) {
      return { events: [...data], total: resTotal.data };
    }
  } catch (error) {
    errorHandler(error);
  }
};

export const fetchEvent = async (id) => {
  try {
    const { data, status } = await axios.get(`/events/${id}`, {});

    if (status === 200) {
      return data;
    }
  } catch (error) {
    errorHandler(error);
  }
};

export const putEvent = async (id, editedEvent) => {
  try {
    const { data, status } = await axios({
      method: 'PUT',
      url: `/events/${id}`,

      data: editedEvent,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    errorHandler(error);
  }
};

export const postEvent = async (newEvent) => {
  try {
    const { data, status } = await axios({
      method: 'POST',
      url: '/events',
      data: newEvent,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    errorHandler(error);
  }
};

export const deleteEvent = async (id) => {
  try {
    const { data, status } = await axios({
      method: 'DELETE',
      url: `/events/${id}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    errorHandler(error);
  }
};
