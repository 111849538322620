import Router from 'next/router';
import axios from 'axios';

import { toast } from 'react-toastify';

import { errorHandler } from '../utils/errorHandler';

export const registerUser = async (reqData) => {
  if (reqData.username && reqData.password && reqData.email) {
    try {
      const { data, status } = await axios.post(
        '/auth/local/register',
        reqData
      );

      if (status === 200) {
        toast('Tu usuario ha sido registrado correctamente');
        Router.push('/confirm-user');
        return data;
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast(
          'Ya existe un usuario registrado con ese Email o Nombre, cámbialo y vuelve a intentarlo'
        );
      }
      errorHandler(error);
    }
  }
};

export const loginUser = async ({ password, email }) => {
  try {
    const { data, status } = await axios.post(
      '/auth/local',
      {
        identifier: email,
        password,
      },
      { headers: { authorization: '' } }
    );

    if (status === 200) {
      return data;
    }
  } catch (error) {
    errorHandler(error, 'El email o contraseña introducido/a no es correcto');
  }
};

export const updateUserInfo = async (userId, info) => {
  try {
    const { data, status } = await axios({
      method: 'PUT',
      url: `/users/${userId}`,
      data: info,
    });

    if (status === 200) {
      toast('Tu información ha sido actualizada correctamente');
      return data;
    }
  } catch (error) {
    errorHandler(error);
  }
};

export const getMeInfo = async () => {
  try {
    const { data, status } = await axios.get('/users/me');

    if (status === 200) {
      return data;
    }
  } catch (error) {
    errorHandler(error);
  }
};

export const changePassword = async (payload) => {
  try {
    const { data, status } = await axios.post('/auth/reset-password', payload);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    errorHandler(error);
  }
};

export const sendRestorePassMail = async (email) => {
  try {
    const { data, status } = await axios.post('/auth/forgot-password', {
      email,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    errorHandler(error);
  }
};

export const deleteUser = async (email, password, id) => {
  try {
    const res = await loginUser({ email, password });

    if (res) {
      const { data, status } = await axios.delete(`/users/${id}`);
      console.log(status);
      console.log(data);
      if (status === 200) {
        return data;
      }
    }
    return;
  } catch (error) {
    errorHandler(error);
  }
};
