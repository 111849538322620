import { actionTypes } from './actions';

const initialState = {
  intranetAccess: false,
  events: [],
  promotedEvents: [],
};

export const reducer = (state = initialState, { type, payload }) => {
  const reducerTypes = {
    [actionTypes.USER_REGISTER_PENDING]: {
      ...state,
      isLoadingRegister: true,
      error: false,
    },
    [actionTypes.USER_REGISTER_SUCCESS]: {
      ...state,
      isLoadingRegister: false,
      error: false,
      ...payload,
    },

    [actionTypes.USER_REGISTER_ERROR]: {
      ...state,
      isLoadingRegister: false,
      error: true,
    },
    [actionTypes.USER_LOGIN_PENDING]: {
      ...state,
      isLoadingLogin: true,
      error: false,
    },
    [actionTypes.USER_LOGIN_SUCCESS]: {
      ...state,
      isLoadingLogin: false,
      error: false,
      ...payload,
    },

    [actionTypes.USER_LOGIN_ERROR]: {
      ...state,
      isLoadingLogin: false,
      error: true,
    },

    [actionTypes.USER_LOGOUT]: {
      ...initialState,
    },

    [actionTypes.INTRANET_PENDING]: {
      ...state,
      isIntranetLoading: true,
      error: false,
    },

    [actionTypes.USER_DATA]: {
      ...state,
      isIntranetLoading: false,
      error: false,
      ...payload,
    },

    [actionTypes.USER_UPDATE_PENDING]: {
      ...state,
      isUserUpdatePending: true,
      error: false,
    },

    [actionTypes.USER_UPDATE_SUCCESS]: {
      ...state,
      isUserUpdatePending: false,
      error: false,
      ...payload,
    },

    [actionTypes.INTRANET_ERROR]: {
      ...state,
      isIntranetLoading: false,
      error: true,
    },
  };

  return reducerTypes[type] || state;
};

export default reducer;
