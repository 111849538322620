import axios from 'axios';

import '../styles/globals.scss';
import 'react-modal-video/scss/modal-video.scss';

import 'react-quill/dist/quill.snow.css';

import { ThemeProvider } from '@material-ui/core/styles';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

import { Provider } from 'react-redux';

import Header from '../layout/Header';
import { useStore } from '../redux/store';

import theme from '../theme';

// Axios defaults initialization
axios.defaults.baseURL = 'https://admin.agendaelsports.es';

function MyApp({ Component, pageProps }) {
  const store = useStore(pageProps.initialReduxState);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <Header />
          <Component {...pageProps} />
          <ToastContainer position="top-center" auto />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default MyApp;
