import { toast } from 'react-toastify';

import Router from 'next/router';

import {
  fetchUserEvents,
  fetchEvent,
  putEvent,
  fetchFeedEvents,
  postEvent,
  deleteEvent,
} from '../../services/eventServices';

export const actionTypes = {
  EVENTS_PENDING: 'EVENTS_PENDING',
  EVENTS_SUCCESS: 'EVENTS_SUCCESS',
  EVENTS_ERROR: 'EVENTS_ERROR',
  EVENT_PENDING: 'EVENT_PENDING',
  EVENT_SUCCESS: 'EVENT_SUCCESS',
  EVENT_ERROR: 'EVENT_ERROR',
  SET_FOCUSED_EVENT: 'SET_FOCUSED_EVENT',
};

export const getUserEvents = (options) => async (dispatch) => {
  dispatch({
    type: actionTypes.EVENTS_PENDING,
  });

  const eventsData = await fetchUserEvents(options);

  if (eventsData?.events) {
    dispatch({
      type: actionTypes.EVENTS_SUCCESS,
      payload: eventsData,
    });
  } else {
    dispatch({
      type: actionTypes.EVENTS_ERROR,
    });
  }
};

export const getEvents = (options) => async (dispatch) => {
  dispatch({
    type: actionTypes.EVENTS_PENDING,
  });

  const eventsData = await fetchFeedEvents(options);

  if (eventsData?.events) {
    dispatch({
      type: actionTypes.EVENTS_SUCCESS,
      payload: { ...eventsData, filters: options?.filters },
    });
  } else {
    dispatch({
      type: actionTypes.EVENTS_ERROR,
    });
  }
};

export const getEvent = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.EVENT_PENDING,
  });

  const event = await fetchEvent(id);

  if (event) {
    dispatch({
      type: actionTypes.EVENT_SUCCESS,
      payload: { focusedEvent: event },
    });
  } else {
    dispatch({
      type: actionTypes.EVENT_ERROR,
    });
  }
};

export const setFocusedEvent = (event) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_FOCUSED_EVENT,
    payload: { focusedEvent: event },
  });
};

export const updateEvent = (id, data) => async (dispatch) => {
  dispatch({
    type: actionTypes.EVENT_PENDING,
  });

  const event = await putEvent(id, data);

  if (event) {
    toast('El evento ha sido modificado correctamente');
    dispatch({
      type: actionTypes.EVENT_SUCCESS,
      payload: { focusedEvent: event },
    });
  } else {
    dispatch({
      type: actionTypes.EVENT_ERROR,
    });
  }
};

export const createEvent = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.EVENT_PENDING,
  });

  const event = await postEvent(data);

  if (event) {
    toast('El evento ha sido creado correctamente');
    dispatch({
      type: actionTypes.EVENT_SUCCESS,
      payload: { focusedEvent: event },
    });
    Router.push('/intranet');
  } else {
    dispatch({
      type: actionTypes.EVENT_ERROR,
    });
  }
};

export const deleteSingleEvent = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.EVENT_PENDING,
  });

  const event = await deleteEvent(data);

  if (event) {
    Router.push('/intranet');
    toast('El evento ha sido eliminado correctamente');
    dispatch({
      type: actionTypes.EVENT_SUCCESS,
      payload: {},
    });
  } else {
    dispatch({
      type: actionTypes.EVENT_ERROR,
    });
  }
};
