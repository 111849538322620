import { actionTypes } from './actions';

const initialState = { options: {} };

export const reducer = (state = initialState, { type, payload }) => {
  const reducerTypes = {
    [actionTypes.OPTIONS_PENDING]: {
      ...state,
      isLoadingSearch: true,
      errorsSearch: false,
    },
    [actionTypes.OPTIONS_SUCCESS]: {
      ...state,
      isLoadingSearch: false,
      errorsSearch: false,
      options: { ...state.options, ...payload },
    },

    [actionTypes.OPTIONS_ERROR]: {
      ...state,
      isLoadingSearch: false,
      errorsSearch: true,
    },
  };

  return reducerTypes[type] || state;
};

export default reducer;
