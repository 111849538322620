import axios from 'axios';
import {
  fetchCategories,
  fetchEventTypes,
  fetchMunicipios,
} from '../../services/searchServices';

export const actionTypes = {
  OPTIONS_PENDING: 'OPTIONS_PENDING',
  OPTIONS_SUCCESS: 'OPTIONS_SUCCESS',
  OPTIONS_ERROR: 'OPTIONS_ERROR',
};

export const getOptions = () => async (dispatch) => {
  dispatch({
    type: actionTypes.OPTIONS_PENDING,
  });

  const [{ data: categories }, { data: eventTypes }, { data: municipios }] =
    await axios.all([fetchCategories(), fetchEventTypes(), fetchMunicipios()]);

  if (categories && eventTypes && municipios) {
    dispatch({
      type: actionTypes.OPTIONS_SUCCESS,
      payload: { categories, eventTypes, municipios },
    });
  } else {
    dispatch({
      type: actionTypes.OPTIONS_ERROR,
    });
  }
};
