import { combineReducers } from 'redux';
import userReducer from './user/reducer';
import eventsReducer from './events/reducer';
import searchReducer from './search/reducer';

// COMBINED REDUCERS
const appReducer = combineReducers({
  user: userReducer,
  events: eventsReducer,
  search: searchReducer,
});

export default appReducer;
